// Font
$f-family: Helvetica, Arial, clean, sans-serif;
$f-mono: Consolas, monaco, monospace;
$f-lineheight: 2vw;
$f-size: 14px;

$f-style: normal;

$f-weight-heavy: 600;
$f-weight-normal: 400;
$f-weight-light: 200;

// #region Widths

$sidebar-container-width: 40px;

// #endregion

// #region Dimensions

// Heights
$header-height: 2em;

// Widths
$narrow-width: 18rem;
$regular-width: 24rem;
$wide-width: 36rem;

// Margins
$logo-margin: 10px;
$nav-item-margin: 10px;

// #endregion

// #region Colors
$accent-color: black;
$background-color: white;
$header-background-color: white;
$sidebar-background-color: white;
$sidebar-item-hover-color: $accent-color;
$sidebar-item-link-color: black;

// #endregion

// #region Typography

// Font Scales
$xs-font-scale: 0.55;
$s-font-scale: 0.889;
$m-font-scale: 1.2;
$l-font-scale: 1.4;
$xl-font-scale: 1.6;
$xxl-font-scale: 2.2;

// #endregion
